import {
  BrowserRouter as Router
} from "react-router-dom";
import "./styles.css";

// fonts
import "./fonts/Nunito-Bold.ttf";
import "./fonts/Nunito-Medium.ttf";
import "./fonts/Nunito-Regular.ttf";

import { Example } from "./Example";
export default function App() {
  return (
    <div className="App">
      <Router>
      <Example />
      </Router>
    </div>
  );
}
