import React from "react";
import { Link, useLocation} from "react-router-dom";

import { gridItemsData } from "./data";
import { Container, Grid, GridItem } from "./Layout";



export const Example = () => {

 /*
  const showAlert = () => {
    window.alert("I'm an alert");
  } */

  return (
    <>
      <Container
        className="example"
        maxWidth={{ md: "sm", lg: "md", xl: "lg" }}
        padding="0rem">
        <div className="toptitle">web3.erc</div>
        <Grid
          gridTemplateColumns={{
            sm: "repeat(2,1fr)",
            md: "repeat(2,1fr)",
            lg: "repeat(3,1fr)"
          }}
          pt="1rem"
          pb="1rem"
          pr="1rem"
          pl="1rem"
          gap="1.2rem"
        >
          {gridItemsData.map((item) => (
              <a href={`https://dev.polydot.network/web3/${item.id}/index.html`} > 
            <GridItem
            // onClick={showAlert}
              className="btntext"
              key={item.id}
              gridArea={item.gridArea}
              padding={{xs: "1.2rem", sm: "1.2rem", md: "2rem", lg: "3rem" }}>
              <span key={item.id}>{item.text}</span><br />
              <span key={item.id}>{item.title}</span>
     </GridItem> 
     </a>  
          ))}
        </Grid>
      </Container>
     
    </>
  );
};
