import { reactSchematic, breakpoints, Breakpoints } from "react-schematic";

// Pass custom breakpoints as min-width
let custom: Breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536
};

// Get responsive styled.div components
export const { Container, Grid, GridItem } = reactSchematic(
  custom || breakpoints
);
