import {
  GridItemResponsiveType,
} from "react-schematic";
/**
 * Grid items data
 */
type GridItemsData = GridItemResponsiveType & {
  text: string; title: string; id: string; name: string; pathname: string;
};
export const gridItemsData: GridItemsData[] = [
  {
    id: "1",
    title: "App_Name",  
    text: "ERC-721",
    name: "blog",
    pathname: "/web3/1/"
   // The gridArea useful behaviour moves a grid items position in the list 
   // based upon the view size and grid items setting sm/md/lg... etc.
   // gridArea: { md: "2/2/span 1/span 1"}
  },
  {
    id: "2",
    title: "App_Name",
    text: "ERC-721",
    name: "blog",
    pathname: "/web3/2/"
    // gridArea: { md: "2/2/span 1/span 1"} 
      
  },
  {
    id: "3",
    title: "App_Name",
    text: "ERC-721",
    name: "blog",
    pathname: "/web3/3/"
  },
  {
    id: "4",
    title: "App_Name",
    text: "ERC-1155",
    name: "blog",
    pathname: "/web3/4/"
  },
  {
    id: "5",
    title: "App_Name",
    text: "ERC-1155",
    name: "blog",
    pathname: "/web3/5/"
   // gridArea: "1/1/span 1/span 1"
  },
  {
    id: "6",
    title: "App_Name",
    text: "ERC-1155",
    name: "blog",
    pathname: "/web3/6/"
  }
];

